import React from 'react';

const FeaturesContent = [
  {
    img: '96',
    title: 'Custom Software Apps',
    desc: `Spacefix.xyz build tailor-made software solutions to meet specific client requirements,
      from enterprise applications to custom CRM systems, and bespoke ERP solutions.`,
  },
  {
    img: '98',
    title: 'Web Apps',
    desc: `We build different kinds of web applications, from simple static landing pages to 
      robost dynamic web solutions like e-commerce platforms, CMS, and web portals.`,
  },
  {
    img: '97',
    title: 'Mobile Apps',
    desc: `We develop mobile applications for smartphones and tablets. Our expertise spans iOS 
      applications, Android applications, and cross-platform mobile solutions.`,
  },
  {
    img: '95',
    title: 'Desktop Apps',
    desc: `We create apps that run on desktop operating systems like Windows, macOS,  
      & Linux. E.g., productivity tools, media players, & standalone software designed for specific tasks.`,
  },
  {
    img: '94',
    title: 'Consulting & Strategy',
    desc: `Leveraging our expertise, Spacefix.xyz provides strategic planning and 
      advisory services to help clients achieve their business goals through technology.`,
  },
  {
    img: '99',
    title: 'SaaS Solutions',
    desc: `Spacefix.xyz offers SAAS solutions specifically designed for the education sector.
      Our cloud-hosted software streamlines processes for institutions & enhances students' learning experiences.`,
  },
];
const FancyFeatureSeventeen = () => {
  return (
    <>
      {FeaturesContent.map((val, i) => (
        <div
          className="col-lg-4 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          key={i}
        >
          <div className="block-meta">
            <div className="icon d-flex align-items-end">
              <img src={`images/icon/${val.img}.svg`} alt="icon" />
            </div>
            <h4>{val.title}</h4>
            <p>{val.desc}</p>
          </div>
        </div>
        // .block-meta
      ))}
    </>
  );
};

export default FancyFeatureSeventeen;
