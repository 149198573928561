import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function TestimonialSeven() {
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    centerMode: true,
    centerPadding: '0',
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const TestimonilContent = [
    {
      img: 'img_7888',
      desc: `The CRM system developed by Spacefix.xyz has been a game-changer for our sales team. It's intuitive and 
        has streamlined our process significantly. Also, the website created for us is exactly what we needed; user friendly and visually appealing.`,
      reviewerName: 'Okechukwu Nzenwaku',
      company: 'Director, Relmart Enterprises',
      bgColor: '#00E5F3',
    },
    {
      img: 'img_7999',
      desc: `We've used Spacefix.xyz's CMS for over a year, and it's been smooth. The system is easy to navigate, 
        and our team adapted quickly. Minor issues were promptly resolved by support. Overall, it's been reliable and efficient.`,
      reviewerName: 'N. Abdulkareem',
      company: 'MD, AppliedHomes Integrated Limited',
      bgColor: '#FF47E2',
    },
    {
      img: 'img_8000',
      desc: `Honestly your designs are impressive. I love them and I won't hesitate to recommend your team to anyone who needs your services.`,
      reviewerName: 'Ileriayo Solomon',
      company: 'PR, Gypsy Capital Limited',
      bgColor: '#8F6BF6',
    },
    {
      img: 'img_8111',
      desc: `You guys have a way of quickly understanding things and delivering exactly what is needed. Working with Spacefix.xyz is straightforward 
        and stress-free. Keep up the excellent work, please!`,
      reviewerName: 'Amadi Frances',
      company: 'Customer Experience, Dthriz Limited',
      bgColor: '#FFCF24',
    },
  ];

  return (
    <Slider {...settings}>
      {TestimonilContent.map((val, i) => (
        <div className="item" key={i}>
          <div className="feedback-wrapper">
            <span className="ribbon" style={{ background: val.bgColor }}></span>
            <p style={{ fontSize: '0.9em', lineHeight: '1.95em' }}>
              <em>"{val.desc}"</em>
            </p>

            <div className="d-flex align-items-center">
              <img
                src={`images/media/${val.img}.png`}
                alt="icon"
                className="avatar"
              />
              <h6 className="name">
                {val.reviewerName}{' '}
                <span>
                  <small>({val.company})</small>
                </span>
              </h6>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
}
