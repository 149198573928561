import React from 'react';
import { Helmet } from 'react-helmet';
import HeaderLandingWebsiteBuilder from '../../components/header/landing/HeaderLandingWebsiteBuilder';
import HeroBannerTwelve from '../../components/hero-banner/HeroBannerTwelve';
import CounterSix from '../../components/counter/CounterSix';
import FooterSeven from '../../components/footer/FooterSeven';
import CopyRightThree from '../../components/footer/CopyRightThree';
import FancyFeatureSeventeen from '../../components/features/FancyFeatureSeventeen';
import FancyTextBlock29 from '../../components/fancy-text-block/FancyTextBlock29';
import TestimonialSeven from '../../components/testimonial/TestimonialSeven';
import CallToActionSeven from '../../components/call-to-action/CallToActionSeven';
import FancyFeatureThirtyTwo from '../../components/features/FancyFeatureThirtyTwo';
import PortfolioTwo from '../../components/portfolio/PortfolioTwo';
import PortfolioThree from '../../components/portfolio/PortfolioThree';
import Portfolio from '../../components/portfolio/Portfolio';
import { Link } from 'react-router-dom';
import FaqClassic from '../../components/faq/FaqClassicTwo';
import Footer from '../../components/footer/Footer';
import CopyRight from '../../components/footer/CopyRight';

const SpacefixApp = () => {
  return (
    <div className="main-page-wrapper p0 font-gordita">
      <Helmet>
        <title>Spacefix.xyz Limited: Software Development Agency</title>
        <meta
          name="description"
          content="Spacefix.xyz Limited is a dynamic software development agency specializing in custom solutions for businesses."
        />
        <meta
          name="keywords"
          content="software development, custom software, CRM development, website development, Spacefix.xyz Limited, software solutions"
        />
      </Helmet>
      {/* End Page SEO Content */}

      {/* 	=============================================
        MENUS
        ==============================================  */}
      <HeaderLandingWebsiteBuilder />

      {/* 	=============================================
        HERO SECTION
        ==============================================  */}
      <HeroBannerTwelve />

      {/* =============================================
        Counter Style Three
        ==============================================  */}
      {/* <div className="counter-style-three lg-container mt-130">
        <div className="container">
          <div className="bottom-border"></div>
        </div>
      </div> */}

      {/* =====================================================
            OUR SERVICES
        ===================================================== */}
      <div
        className="fancy-feature-seventeen lg-container mt-50 md-mt-30"
        id="services"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="title-style-thirteen text-center mb-130 md-mb-90">
                <div className="upper-title">Our Services</div>
                <h2 style={{ color: '#182F51' }}>
                  Explore our services and take your{' '}
                  <span>
                    pick of the litter.
                    <img
                      src="images/shape/line-shape-14.svg"
                      alt="line shape"
                    />
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="bottom-border pb-50">
            <div className="row">
              <FancyFeatureSeventeen />
            </div>
          </div>
        </div>
      </div>

      {/* =============================================
            Fancy Feature Twenty Nine
        ==============================================  */}
      {/* <div
        className="fancy-feature-twentyNine lg-container pt-150 mt-20 md-pt-70"
        id="p-tour"
      >
        <FancyTextBlock29 />
      </div> */}

      {/* =============================================
            OUR SPECIALTY
        ==============================================  */}
      <div className="fancy-feature-thirty mt-150 md-mt-90" id="specialty">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 col-lg-9 m-auto">
              <div className="title-style-thirteen text-center mb-80 md-mb-60">
                <div className="upper-title">Our Specialty</div>
                {/* <h2>
                  Deski for every{' '}
                  <span>
                    business{' '}
                    <img
                      src="images/shape/line-shape-14.svg"
                      alt="line shape"
                    />
                  </span>
                  You need.
                </h2> */}

                <h2 style={{ color: '#182F51' }}>
                  Across sectors, we shine{' '}
                  <span>
                    brightest{' '}
                    <img
                      src="images/shape/line-shape-14.svg"
                      alt="line shape"
                    />
                  </span>
                  here.
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="container">
            <FancyFeatureThirtyTwo />
          </div>
          <img
            src="images/shape/237.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/238.svg"
            alt="shape"
            className="shapes shape-two"
          />
        </div>
        {/* <!-- /.wrapper --> */}
      </div>
      {/* <!-- /.fancy-feature-thirty --> */}

      <CallToActionSeven />
      {/* End call to acion seven */}

      {/* =====================================================
            Client Feedback Slider Six
        ===================================================== */}
      <div
        className="client-feedback-slider-six pt-150 md-pt-120"
        id="testimonials"
      >
        <div className="inner-container">
          <div className="title-style-thirteen text-center">
            <div className="upper-title">What Clients Say</div>
            <h2 style={{ color: '#182F51' }}>
              We've earned{' '}
              <span>
                our flowers!{' '}
                <img src="images/shape/line-shape-14.svg" alt="line shape" />
              </span>
              <br />
              Hear it directly.
            </h2>
          </div>
          <div className="clientSliderSix style-two mt-50 md-mt-0">
            <TestimonialSeven />
            <div className="container">
              <div className="bottom-border pb-50">
                <div className="row"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="fancy-portfolio-two lg-container pt-150 md-pt-100"
        id="works-done"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="title-style-thirteen text-center mb-130 md-mb-90">
                <div className="upper-title">Works We've Done</div>
                <h2 style={{ color: '#182F51' }}>
                  Got a curious streak? Here's{' '}
                  <span>
                    something for you.
                    <img
                      src="images/shape/line-shape-14.svg"
                      alt="line shape"
                    />
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <PortfolioTwo />
        </div>
      </div>

      <div className="footer-bg-wrapper">
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="fancy-short-banner-two">
          <div className="container">
            <div className="content-wrapper">
              <div
                className="
                  bg-wrapper
                  d-lg-flex
                  align-items-center
                  justify-content-between
                "
              >
                <h2 style={{ lineHeight: 1.3 }}>
                  Tell us <span style={{ color: '#FFCF24' }}>exactly</span> how
                  you want it, and consider it{' '}
                  <span style={{ color: '#FFCF24' }}>done</span>!
                </h2>
                <a href="mailto:info@spacefix.xyz" className="theme-btn-twelve">
                  Send an Email
                </a>
                <div className="bubble-one"></div>
                <div className="bubble-two"></div>
                <div className="bubble-three"></div>
                <div className="bubble-four"></div>
                <div className="bubble-five"></div>
              </div>
              {/* /.bg-wrapper */}
            </div>
            {/*  /.content-wrapper */}
          </div>
          {/* /.container */}
        </div>

        {/* <footer className="theme-footer-one pt-130 md-pt-70">
          <div className="top-footer">
            <div className="container">
              <Footer />
            </div>
          </div>

          <div className="container">
            <div className="bottom-footer-content">
              <CopyRightThree />
            </div>
          </div>
        </footer> */}
      </div>

      {/* =====================================================
        Footer Style Seven
        ===================================================== */}
      <footer className="theme-footer-seven">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <FooterSeven />
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default SpacefixApp;
