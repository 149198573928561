import React from 'react';
import { Link } from 'react-router-dom';
import FormFooterSignup from '../form/FormFooterSignup';

const FooterSeven = () => {
  return (
    <div className="row">
      <div
        className="col-xl-4 col-lg-4 col-md-12 mt-190 md-mt-120"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="logo">
          <Link to="/">
            {/* <img src="images/logo/deski_06.svg" alt="logo" /> */}
            <img
              height="60px"
              width="auto"
              src="images/logo/logo.png"
              alt="brand"
            />
          </Link>
        </div>
      </div>

      <div
        className="col-lg-4 col-md-12 mt-190 md-mt-80"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="150"
      >
        <h5 className="title">Quick Links</h5>
        <ul className="footer-list">
          <li>
            <a href="#services">Services</a>
          </li>
          <li>
            <a href="#specialty">Specialty</a>
          </li>
          <li>
            <a href="#testimonials">Client Testimonials</a>
          </li>
          <li>
            <a href="#works-done">Works Done</a>
          </li>
        </ul>
      </div>
      <div
        className="col-xl-4 col-lg-4 mt-190 md-mt-80"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="200"
      >
        <div className="newsletter">
          <h5 className="title">Contact Info</h5>
          <ul className="footer-list">
            <li>
              <Link to="/">
                5b, Samuel Adedoyin Street, Victoria Island, Lagos, NG.
              </Link>
            </li>
            <li>
              <a href="mailto:info@spacefix.xyz">info@spacefix.xyz</a>
            </li>
            <li>
              <a href="tel:+2348101715824">+2348101715824</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FooterSeven;
