import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import AllRoutes from './router/AllRoutes';
import ScrollToTop from './components/ScrollToTop';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'photoswipe/dist/photoswipe.css';
import 'bootstrap/dist/js/bootstrap';

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  window.addEventListener('load', AOS.refresh);

  return (
    <>
      <Helmet>
        <title>Spacefix.xyz Limited: Software Development Agency</title>
        <meta
          name="keywords"
          content="software development, custom software, CRM development, website development, Spacefix.xyz Limited, software solutions"
        />

        {/* Open Graph meta tags  */}
        <meta property="og:site_name" content="Spacefix.xyz" />
        <meta
          property="og:title"
          content="Spacefix.xyz Limited: Software Development Agency"
        />
        <meta
          name="description"
          content="Spacefix.xyz Limited is a dynamic software development agency specializing in custom solutions for businesses."
        />
        <meta property="og:url" content="https://www.spacefix.xyz/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <ScrollToTop />

      <AllRoutes />
    </>
  );
};

export default App;
