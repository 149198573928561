import React from 'react';

const CallToActionSeven = () => {
  return (
    <div className="fancy-short-banner-fourteen mt-150 md-mt-80">
      <div className="container">
        <div className="row">
          <div className="col-12" data-aos="fade-up" data-aos-duration="1200">
            <div className="title-style-thirteen">
              <h2 style={{ lineHeight: 1.3 }}>
                Got any <span style={{ color: '#00E5F3' }}>Projects</span>?{' '}
                <br />
                Come on, let's talk about it!
              </h2>
            </div>
            <p>Your success is our priority.</p>
            <a href="mailto:info@spacefix.xyz" className="theme-btn-twelve">
              Start here
            </a>
          </div>
        </div>
      </div>
      {/* <!-- /.container --> */}
      <img
        src="images/shape/239.svg"
        alt="shape"
        className="shapes shape-one"
      />
      <img
        src="images/shape/240.svg"
        alt="shape"
        className="shapes shape-two"
      />
      <img
        src="images/shape/232.svg"
        alt="shape"
        className="shapes shape-three"
      />
      <img
        src="images/shape/233.svg"
        alt="shape"
        className="shapes shape-four"
      />
      <img
        src="images/shape/234.svg"
        alt="shape"
        className="shapes shape-five"
      />
      <img
        src="images/shape/235.svg"
        alt="shape"
        className="shapes shape-six"
      />
      <img
        src="images/shape/236.svg"
        alt="shape"
        className="shapes shape-seven"
      />
      <img
        src="images/shape/232.svg"
        alt="shape"
        className="shapes shape-eight"
      />
    </div>
  );
};

export default CallToActionSeven;
