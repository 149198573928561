import React from 'react';

const featuresContent = [
  {
    icon: '13',
    title: 'Education',
    descriptions: `We provide software that simplifies administration and enriches the learning experience for educators and students.`,
    link: '#',
    dealyAnimation: '',
  },
  {
    icon: '12',
    title: 'Healthcare',
    descriptions:
      'Spacefix.xyz enhances patient care and operational efficiency with tailored software solutions for healthcare providers',
    link: '#',
    dealyAnimation: '100',
  },
  {
    icon: '14',
    title: 'Finance',
    descriptions:
      'We improve financial operations with secure, efficient software designed to streamline management and support growth.',
    link: '#',
    dealyAnimation: '',
  },
  {
    icon: '09',
    title: 'Agriculture',
    descriptions:
      'Spacefix.xyz supports agricultural success with smart software solutions that enhance productivity & promote sustainable practices.',
    link: '#',
    dealyAnimation: '100',
  },
];

const FancyFeatureThirtyTwo = () => {
  return (
    <div className="row">
      {featuresContent.map((item, i) => (
        <div
          className="col-md-6"
          data-aos="fade-up"
          data-aos-delay={item.dealyAnimation}
          key={i}
        >
          <div className="block-style-thirtyTwo d-flex">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: item.bgColor }}
            >
              <img src={`images/icon/${item.icon}.svg`} alt="icon" />
            </div>
            <div className="text">
              <h4>{item.title}</h4>
              <p>{item.descriptions}</p>
              {/* <a href={item.link} className="theme-btn-ten">
                Learn more
                <i className="fa fa-chevron-right" aria-hidden="true"></i>
              </a> */}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FancyFeatureThirtyTwo;
