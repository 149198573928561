import React from 'react';
import { Link } from 'react-router-dom';

const HeroBannerTwelve = () => {
  return (
    <div className="hero-banner-twelve lg-container" id="home">
      <div className="container">
        <div className="row">
          <div className="col-xl-11 col-lg-12 col-md-10 m-auto text-center mt-10">
            <h1 className="hero-heading font-gordita" data-aos="fade-up">
              We build <span style={{ color: '#FEDC00' }}>websites</span>,{' '}
              <span style={{ color: '#FF7B00' }}>portals</span>,{' '}
              <span style={{ color: '#6F55FF' }}>mobile</span> &{' '}
              <span style={{ color: '#25E5DA' }}>desktop</span> apps.
              {/* #FF006B-pink #6F55FF-dark violet*/}
            </h1>
            <p
              className="hero-sub-heading"
              data-aos="fade-up"
              data-aos-delay="100"
              style={{ fontSize: '0.9em', lineHeight: '1.5em' }}
            >
              Spacefix.xyz is a dynamic software development agency specializing
              <br /> in custom solutions for businesses.
            </p>
            <div data-aos="fade-up" data-aos-delay="200">
              <a href="mailto:info@spacefix.xyz" className="get-start-btn">
                Tell us your project{' '}
                <i className="fa fa-angle-right ms-1" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          <div className="mb-250"></div>
        </div>

        {/* <div className="screen-holder">
          <img
            src="images/assets/screen_32.png"
            alt="screen"
            className="img-meta"
          />
          <img
            src="images/assets/screen_33.png"
            alt="screen"
            className="shapes screen-one"
          />
          <img
            src="images/assets/screen_34.png"
            alt="screen"
            className="shapes screen-two"
          />
          <img
            src="images/shape/230.svg"
            alt="screen"
            className="shapes shape-one"
          />
          <img
            src="images/shape/231.svg"
            alt="screen"
            className="shapes shape-two"
          />
        </div> */}
        <img
          src="images/shape/232.svg"
          alt="shape"
          className="shapes shape-three"
        />
        <img
          src="images/shape/233.svg"
          alt="shape"
          className="shapes shape-four"
        />
        <img
          src="images/shape/234.svg"
          alt="shape"
          className="shapes shape-five"
        />
        <img
          src="images/shape/235.svg"
          alt="shape"
          className="shapes shape-six"
        />
        <img
          src="images/shape/236.svg"
          alt="shape"
          className="shapes shape-seven"
        />
        <img
          src="images/shape/232.svg"
          alt="shape"
          className="shapes shape-eight"
        />
      </div>
    </div>
  );
};

export default HeroBannerTwelve;
